import React from 'react';
import { InformationBox } from '@components/common';
import { Box, Code, Text } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/solid';

export function AvatarImageUrlPropertyNotice(): JSX.Element | null {
  return (
    <InformationBox
      icon={InformationCircleIcon}
      textStyle='sm-normal'
      color='gray.500'
      iconColor='primary.500'
    >
      <Box>
        <Text textStyle='sm-normal'>
          With the rollout of Avatars, the older{' '}
          <Code>user.profile_image_url</Code> and{' '}
          <Code>organization.logo_url</Code> properties are now deprecated in
          favor of the new <Code>image_url</Code>. To use the new Avatars for
          users and organizations, use the new properties instead.
        </Text>
      </Box>
    </InformationBox>
  );
}

export function AvatarCacheTimeNotice(): JSX.Element | null {
  return (
    <InformationBox
      icon={InformationCircleIcon}
      textStyle='sm-normal'
      color='gray.500'
      iconColor='primary.500'
    >
      <Box>
        <Text textStyle='sm-normal'>
          Once downloaded for the first time, Avatars are cached for 10 minutes
          by the browser. If you change your avatar settings, it will take up to
          10 minutes for the changes to be reflected. During development, you
          can clear your browser cache to see the new Avatars immediately.
        </Text>
      </Box>
    </InformationBox>
  );
}
