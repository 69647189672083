import React from 'react';
import { Flex } from '@chakra-ui/react';
import Link from 'next/link';
import NextImage from 'next/image';
import { useHeaderContext } from './headerContext';
import { useAnalytics } from '@hooks/useAnalytics';

export function LogoLink(): JSX.Element {
  const { track } = useAnalytics();
  const { isSticky } = useHeaderContext();

  return (
    <Flex cursor='pointer' transform={isSticky ? 'scale(0.75)' : 'scale(1)'}>
      <Link href='/'>
        <NextImage
          src='/assets/logos/clerk.svg'
          alt="Clerk's logo"
          height={30}
          width={30}
          onClick={() => {
            track('Dashboard_Top Nav_Menu Item Clicked', {
              surface: 'Dashboard',
              location: 'Top Nav',
              clickedOn: 'Home',
            });
          }}
        />
      </Link>
    </Flex>
  );
}
