import React from 'react';
import { Link, HStack } from '@chakra-ui/react';
import { CardSinglePanel } from '@components/common';
import { useAnalytics } from '@hooks/useAnalytics';

const linkProps = {
  target: '_blank',
  textStyle: 'sm-normal',
  color: 'primary.500',
  fontWeight: 500,
};

export function DocumentationAndSupport(): JSX.Element {
  const { track } = useAnalytics();

  return (
    <CardSinglePanel
      title='Documentation & support'
      subtitle='Learn how to build, connect, and deploy Clerk by reading our extensive documentation. If you have questions or need assistance, join our community Discord channel to talk to us. We’re here to help!'
      subtitleStyles={{ mb: 6 }}
    >
      <HStack spacing={4}>
        <Link
          href='https://clerk.com/docs'
          onClick={() => {
            track(
              'Dashboard_Instance Overview_Documentation & Support Card Link Clicked',
              {
                surface: 'Dashboard',
                location: 'Instance Overview',
                clickedOn: 'Read Documentation',
              },
            );
          }}
          {...linkProps}
        >
          Read documentation
        </Link>

        <Link
          href='https://discord.com/invite/b5rXHjAg7A'
          onClick={() => {
            track(
              'Dashboard_Instance Overview_Documentation & Support Card Link Clicked',
              {
                surface: 'Dashboard',
                location: 'Instance Overview',
                clickedOn: 'Join community Discord',
              },
            );
          }}
          {...linkProps}
        >
          Join community Discord
        </Link>
      </HStack>
    </CardSinglePanel>
  );
}
