import React from 'react';
import { Center, HStack } from '@chakra-ui/react';
import { UserButton } from '@clerk/nextjs';
import { Help } from './Help';
import { useAnalytics } from '@hooks/useAnalytics';

export function Navigation(): JSX.Element {
  const { track } = useAnalytics();

  return (
    <HStack spacing={2} align='center'>
      <Help />
      <Center
        pl={3}
        onClick={() => {
          track('Dashboard_Top Nav_Menu Item Clicked', {
            surface: 'Dashboard',
            location: 'Top Nav',
            clickedOn: 'User Profile',
          });
        }}
      >
        <UserButton afterSwitchSessionUrl='/' />
      </Center>
    </HStack>
  );
}
