import React from 'react';
import { CardDualPanel } from '@components/common';
import { Flex, Box } from '@chakra-ui/react';

import { useFeatureFlags } from '@hooks';

import { RegisteredSwitchInfoField as SwitchInfoField } from '@components/common/Switch';
import NextLink from 'next/link';

const Permissions = ({
  isOrganizationsEnabled,
  applicationId,
  instanceId,
}: {
  isOrganizationsEnabled: boolean;
  applicationId: string;
  instanceId: string;
}): JSX.Element => {
  const {
    featureFlags: { allow_user_create_org, allow_user_self_delete },
    isLoading: isFeatureFlagsLoading,
  } = useFeatureFlags();

  if (
    !allow_user_create_org ||
    !allow_user_self_delete ||
    isFeatureFlagsLoading
  ) {
    return <></>;
  }

  return (
    <CardDualPanel
      title='User permissions'
      subtitle='Define the permissions for this user. This overrides the default permissions as defined in User Settings.'
      rightPanelStyles={{ flex: 1, width: 'calc(50% - 1.5rem)' }}
    >
      <Flex flexDir='column' gap={5}>
        <SwitchInfoField
          description=''
          title='Allow user to delete their account'
          name='delete_self_enabled'
          aria-label='Allow user to delete their account'
        />
        <SwitchInfoField
          info={
            !isOrganizationsEnabled ? (
              <>
                Cannot be enabled because organizations are not enabled for this
                application.
                <NextLink
                  href={`/apps/${applicationId}/instances/${instanceId}/organizations-settings`}
                >
                  <Box
                    as='span'
                    textStyle='sm-normal'
                    color='primary.500'
                    fontWeight={500}
                  >
                    Go to Organization Settings
                  </Box>
                </NextLink>{' '}
              </>
            ) : null
          }
          isDisabled={!isOrganizationsEnabled}
          description=''
          title='Allow user to create organizations'
          name='create_organization_enabled'
          aria-label='Allow user to create organizations'
        />
      </Flex>
    </CardDualPanel>
  );
};

export default Permissions;
