import { useLocation } from '@hooks';
import { useSession, useOrganization } from '@clerk/nextjs';
import { makeOrganizationsRootPath } from '@components/organizations/utils';
import { buildCacheKey } from '@utils/api';

import { PAGE_SIZE } from '@components/organizations';

import React from 'react';

const useOrganizationsPathAndKeys = ({
  orderBy,
  search,
  page,
}: {
  orderBy?: string;
  search?: string;
  page?: number;
} = {}): {
  cacheKey: string;
  path: string;
  pathWithParams: string;
  makeInfinitePath: (
    search: string,
    userID: string,
  ) => (pageIndex: number, previousPageData: any) => string;
} => {
  const { instanceId, query } = useLocation();
  const { session } = useSession();
  const { organization } = useOrganization();

  const path = makeOrganizationsRootPath(instanceId);

  const _search = search || (query?.query as string) || '';
  const _page = page || parseInt(query.page as string, 10) || 1;
  const _orderBy = orderBy || (query?.organizations_order_by as string) || '';

  const params = new URLSearchParams({
    query: _search,
    offset: PAGE_SIZE * (_page - 1) + '',
    ...(_orderBy ? { order_by: _orderBy } : {}),
  });

  const pathWithParams = `${path}?${params.toString()}`;

  const cacheKey = buildCacheKey({
    path: pathWithParams,
    session,
    id: organization?.id,
  });

  const makeInfinitePath = React.useCallback(
    (search: string, userID: string) =>
      (pageIndex: number): string => {
        const params = new URLSearchParams({
          query: search,
          ...(userID ? { user_id: `-${userID}` } : {}),
          offset: PAGE_SIZE * pageIndex + '',
        });
        return `${path}?${params.toString()}`;
      },
    [path],
  );

  return { cacheKey, pathWithParams, path, makeInfinitePath };
};

export default useOrganizationsPathAndKeys;
