import React from 'react';
import {
  Button,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useRadioGroup,
} from '@chakra-ui/react';
import { Modal } from '@components/common';
import { useRouter } from 'next/router';
import { useLocation } from '@hooks';
import { RadioTile } from './RadioTile';
import { svgUrl } from '@utils';
import { makeJwtTemplateStarterList } from '@types';

interface AddNewTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function AddNewTemplateModal({
  isOpen,
  onClose,
}: AddNewTemplateModalProps): JSX.Element {
  const { push } = useRouter();
  const { applicationId, instanceId } = useLocation();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'vendor',
    onChange: async (nextValue: string) => {
      await push(
        `/apps/${applicationId}/instances/${instanceId}/jwt-templates/new?starter=${nextValue}`,
      );
    },
  });

  const group = getRootProps();

  const enabledStarters = makeJwtTemplateStarterList().filter(
    ({ enabled }) => enabled,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='New JWT template'
      autoFocus={false}
      size='md'
    >
      <Modal.CloseButton />

      <Modal.Body>
        <Text>Select a template to get started.</Text>

        <SimpleGrid columns={3} spacing='4' mt='8' {...group}>
          {enabledStarters.map(({ vendor, label }) => {
            const radio = getRadioProps({ value: vendor });

            return (
              <RadioTile key={vendor} {...radio}>
                <VStack spacing='2' p='2'>
                  <Image src={svgUrl(vendor)} alt={label} boxSize='20px' />
                  <Text textStyle='sm-normal'>{label}</Text>
                </VStack>
              </RadioTile>
            );
          })}
        </SimpleGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='ghost'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
