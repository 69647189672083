import React from 'react';
import { Stack } from '@chakra-ui/layout';
import { SamlConnection } from '@types';
import { useInstance, useLocation, useToast } from '@hooks';
import {
  CardDualPanel,
  CardWithSwitch,
  CopyTextInput,
  InformationBox,
  ReadonlyInputBox,
} from '@components/common';
import { ConfirmationModal } from '@components/modal';
import {
  Box,
  Center,
  Spinner,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { CompleteConnectionNotice } from '@components/samlConnections';
import {
  useGetSamlConnection,
  useUpdateSamlConnection,
} from '@components/samlConnections/api';
import { HTTPError, isProduction } from '@utils';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

export function SamlConnectionSettings(): JSX.Element {
  const { query } = useLocation();
  const { samlConnectionId } = query as Record<string, string | undefined>;
  const { updateSamlConnection } = useUpdateSamlConnection();
  const { showSuccessToast, showErrorToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formMethods = useForm<SamlConnection>({ mode: 'onChange' });
  const { register, watch, reset } = formMethods;

  const isChecked = watch('active');

  const { instance, isValidating: isValidatingInstance } = useInstance();

  const {
    data: samlConnection,
    isLoading: samlConnectionIsLoading,
    mutate: mutateSamlConnection,
  } = useGetSamlConnection(samlConnectionId);

  React.useEffect(() => {
    if (!samlConnection) {
      return;
    }

    const { active } = samlConnection;

    reset({ active });
  }, [samlConnection, reset]);

  const handleSwitchChange = e => {
    const isChecked = e.target.checked;
    const showBillingConfirmation =
      instance && isProduction(instance.environment_type) && isChecked;

    // If connection is being deactivated or activated for prod instances,
    // ask user to confirm
    if (!isChecked || showBillingConfirmation) {
      onOpen();
    } else {
      return handleUpdate({ active: isChecked });
    }
  };

  const handleCancelation = () => {
    onClose();
    reset();
  };

  const handleConfirmation = async () => {
    await handleUpdate({ active: !isChecked });
    onClose();
  };

  const handleUpdate = async ({ active }: { active: boolean }) => {
    try {
      await updateSamlConnection(samlConnectionId, { active });
      showSuccessToast('Connections settings saved.');
      void mutateSamlConnection();
    } catch (err) {
      if (err.name === 'HTTPError' && err.code !== 500) {
        const error = (err as HTTPError)?.globalErrors[0];
        showErrorToast(
          error.long_message || 'Something went wrong, please try again',
        );
      } else {
        showErrorToast('Something went wrong, please try again later.');
        reset();
      }
    }
  };

  if (
    (isValidatingInstance && !instance) ||
    (samlConnectionIsLoading && !samlConnection)
  ) {
    return (
      <Center w='full' h='full'>
        <Spinner />
      </Center>
    );
  }

  return (
    <Stack spacing='8'>
      <CompleteConnectionNotice />

      <FormProvider {...formMethods}>
        <CardWithSwitch
          title='Enable connection'
          subtitle='Allow users to authenticate using this enterprise connection.'
        >
          <Box w='48px' h='48px'>
            <Switch
              {...register('active')}
              isChecked={isChecked}
              onChange={handleSwitchChange}
              aria-label='Enable connection'
            />
          </Box>
        </CardWithSwitch>

        <ConfirmationModal
          isOpen={isOpen}
          handleCancelation={handleCancelation}
          handleConfirmation={handleConfirmation}
          confirmationMessage={
            isChecked ? 'Disable connection' : 'Enable connection'
          }
          colorScheme={isChecked ? 'red' : 'primary'}
          cancelationMessage='Cancel'
          message={
            isChecked ? (
              <>
                <Text textStyle='md-normal'>
                  Are you sure you want to disable{' '}
                  <Text as='span' textStyle='md-semibold'>
                    {samlConnection.name}
                  </Text>
                  ?
                </Text>
                <Text textStyle='md-normal'>
                  Users will no longer be able to use this enterprise connection
                  to sign in. Any features that rely on this connection may
                  cease to function.
                </Text>
              </>
            ) : (
              <InformationBox
                icon={ExclamationCircleIcon}
                iconColor='warning.300'
              >
                <VStack align='start' spacing='1'>
                  <Text textStyle='md-semibold'>
                    By enabling this enterprise connection, a charge of $50 will
                    be added to this month's invoice.
                  </Text>
                  <Text textStyle='md-normal'>
                    Are you sure you want to proceed?
                  </Text>
                </VStack>
              </InformationBox>
            )
          }
          headerTitle={isChecked ? 'Disable connection' : 'Confirmation'}
        />
      </FormProvider>

      <CardDualPanel title='Connection details' subtitle=''>
        <ReadonlyInputBox label='Domain' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.domain}
          </Text>
        </ReadonlyInputBox>
      </CardDualPanel>

      <CardDualPanel
        title='Identity provider configuration'
        subtitle='These are the values that you copied from your identity provider.'
      >
        <ReadonlyInputBox label='IdP SSO URL' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.idp_sso_url}
          </Text>
        </ReadonlyInputBox>

        <ReadonlyInputBox label='IdP Entity ID' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.idp_entity_id}
          </Text>
        </ReadonlyInputBox>

        <ReadonlyInputBox label='Certificate' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.idp_certificate
              ? 'Certificate uploaded'
              : 'No certificate uploaded'}
          </Text>
        </ReadonlyInputBox>
      </CardDualPanel>

      <CardDualPanel
        title='Service provider details'
        subtitle='These are the values to add to your identity provider.'
      >
        <CopyTextInput
          label='Assertion consumer service URL'
          valueToCopy={samlConnection.acs_url}
          paddingTop={0}
        />

        <CopyTextInput
          label='Service provider entity ID'
          valueToCopy={samlConnection.sp_entity_id}
          paddingTop={0}
        />

        <CopyTextInput
          label='Sign-in URL'
          valueToCopy={instance.active_display_config.sign_in_url}
          paddingTop={0}
        />
      </CardDualPanel>
    </Stack>
  );
}
