import React, { useState } from 'react';

import { useLocation } from '@hooks';
import { useRouter } from 'next/router';

import { organizationSchema, OrganizationSchema } from './schema';

import { useGetOrganizationsFromDAPI } from '@components/organizations/core';
import { Organization } from '@components/organizations/core/types';
import { TableSortingData, TableSortingDirections } from '@types';

const makeOrganizationSchema = organizationSchema();

type OrganizationTableReturnType = {
  data: Organization[];
  schema: OrganizationSchema;
  totalCount: number;
  page: number;
  query: string;
  isLoading: boolean;
  sortingState: TableSortingData;
  error: string;
  onRowClicked: (organization: Organization) => void;
  onPaginate: (value: number) => void;
  onSorted: (sortingData: TableSortingData) => void;
};
const useTable = (): OrganizationTableReturnType => {
  const [sortingState, setSortingState] = useState<TableSortingData>({
    column: 'created_at',
    direction: TableSortingDirections.DESC,
  });

  const router = useRouter();
  const { instanceId, applicationId } = useLocation();

  const {
    data: organizations,
    totalCount,
    hasLoaded,
    error,
  } = useGetOrganizationsFromDAPI();

  const page = parseInt(router.query.page as string, 10) || 1;
  const organizationProfilePath = `/apps/${applicationId}/instances/${instanceId}/organizations`;

  const onRowClicked = React.useCallback(
    (organization: Organization) => {
      void router.push({
        pathname: `${organizationProfilePath}/${organization.id}`,
        query: {
          page: page,
        },
      });
    },
    [page, organizationProfilePath, router],
  );
  const onPaginate = (value: number) => {
    void router.push({ query: { ...router.query, page: value } });
  };

  const onSorted = (sortingData: TableSortingData) => {
    setSortingState(sortingData);
    void router.push({
      query: {
        ...router.query,
        organizations_order_by: `${sortingData.direction}${sortingData.column}`,
      },
    });
    if (page > 1) {
      onPaginate(1);
    }
  };

  return {
    data: organizations,
    isLoading: !hasLoaded,
    schema: makeOrganizationSchema,
    page,
    query: (router.query.query as string) || '',
    sortingState,
    totalCount,
    error,
    onRowClicked,
    onPaginate,
    onSorted,
  };
};

export default useTable;
