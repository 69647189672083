import React from 'react';
import Link from 'next/link';
import { Link as ChakraLink, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useApplication } from '@hooks';
import { InformationBox, PageLoader } from '../common';
import { FirebaseCard } from './firebase';
import { GoogleAnalyticsCard } from './googleAnalytics';
import { HasuraCard } from './hasura';

export const Integrations = (): JSX.Element => {
  const { application } = useApplication();

  if (!application) {
    return <PageLoader />;
  }

  // The legacy Hasura integration is deprecated in favor on new JWT Templates
  const shouldShowHasuraCard =
    new Date(application.created_at).getTime() <
    new Date('2022-02-17').getTime();

  return (
    <Stack spacing={7}>
      <SimpleGrid minChildWidth='320px' spacing='32px' mx='auto' w='full'>
        <FirebaseCard />
        <GoogleAnalyticsCard />
        {shouldShowHasuraCard && <HasuraCard />}
      </SimpleGrid>

      <InformationBox icon={ExclamationCircleIcon}>
        <Text textStyle='md-normal' display='inline-block' color='gray.500'>
          Need an integration but don’t see it here?{' '}
          <Link href='mailto:support@clerk.com' passHref>
            <ChakraLink textTransform='unset' color='primary.500'>
              Request integration
            </ChakraLink>
          </Link>
        </Text>
      </InformationBox>
    </Stack>
  );
};
