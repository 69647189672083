import React, { useEffect } from 'react';
import { InputGroup, InputLeftAddon, Text } from '@chakra-ui/react';
import { Flex, VStack } from '@chakra-ui/layout';
import { PathEntryInfo } from './PathEntryInfo';
import { useFormContext } from 'react-hook-form';
import { EditPathButton } from './EditPathButton';
import { InputWithTransform } from './InputWithTransform';
import { useAnalytics, useLocation } from '@hooks';

/*
The input transform behavior should work as described here:
1. Path == null => When click `Close`
2. Path == "" => Input == "/" & Open
3. Path == "/foo" => Input == "/foo" & Open
*/
const defaultTransformInput = (value: string | null | undefined): string => {
  if (value === '') {
    return '/';
  }
  if (!value) {
    return '';
  }
  return value;
};

const defaultTransformOutput = (
  e: React.ChangeEvent<HTMLInputElement>,
): string => {
  const value = e.target.value;
  if (value === '') {
    return null;
  }
  if (value === '/') {
    return '';
  }
  return value;
};

type UrlSettingProps = {
  title: string;
  subtitle: string;
  inputPlaceholder: string;
  customPathKey: string;
  defaultUrl: string;
  info?: string;
  customPath?: string;
  /* Used to circumvent the "complex layout" */
  isLast: boolean;
  transform?: {
    input?: (value: string | null | undefined) => string;
    output?: (e: React.ChangeEvent<HTMLInputElement>) => string | null;
  };
  applyDefaultValue?: boolean;
  showPathPrefix?: boolean;
};

export function UrlSetting({
  title,
  subtitle,
  inputPlaceholder,
  customPathKey,
  info,
  defaultUrl,
  customPath,
  isLast,
  transform,
  applyDefaultValue,
  showPathPrefix,
}: UrlSettingProps): JSX.Element {
  const [isEditing, setIsEditing] = React.useState(!!customPath);

  useEffect(() => {
    setIsEditing(!!customPath);
  }, [customPath]);

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();
  const { track } = useAnalytics();
  const { instanceId } = useLocation();

  const handleRemoveCustomPath = () => {
    if (isEditing) {
      setValue(customPathKey, applyDefaultValue ? defaultUrl : null, {
        shouldDirty:
          (customPath && getValues(customPathKey)) ||
          (customPath === '' && getValues(customPathKey) === ''),
      });
      setIsEditing(false);
    } else {
      track('Dashboard_Paths Screen_Paths Setting Icon Clicked', {
        location: 'Paths Screen',
        surface: 'Dashboard',
        instanceId,
        clickedOn: title,
      });

      setIsEditing(true);
    }
  };

  const PathSettingPrefix = () => {
    return <>{new URL(defaultUrl).origin}</>;
  };

  return (
    <VStack
      alignItems='flex-start'
      mb={isLast ? 0 : isEditing ? '1.5rem' : '2.5rem'}
    >
      <Flex width='100%' justifyContent='space-between' alignItems='center'>
        <Text textStyle='md-medium'>{title}</Text>
        <EditPathButton
          isEditing={isEditing}
          handleClick={handleRemoveCustomPath}
        />
      </Flex>
      {isEditing ? (
        <>
          <Text textStyle='sm-normal' color='gray.500'>
            {subtitle}
          </Text>
          {/* TODO: Should this be in common ? */}
          <InputGroup>
            {showPathPrefix && (
              <InputLeftAddon bg='gray.50'>
                <PathSettingPrefix />
              </InputLeftAddon>
            )}
            <InputWithTransform
              control={control}
              name={customPathKey}
              transform={{
                input: transform?.input || defaultTransformInput,
                output: transform?.output || defaultTransformOutput,
              }}
              placeholder={inputPlaceholder}
              hasPrefix={showPathPrefix}
            />
          </InputGroup>
          {errors[customPathKey] && (
            <Text color='danger.500' textStyle='sm-normal'>
              {errors[customPathKey]?.message ||
                `Invalid path for "${title.toLowerCase()}"`}
            </Text>
          )}
        </>
      ) : (
        <Text textStyle='sm-normal' color='gray.500'>
          {defaultUrl || '(auto)'}
        </Text>
      )}
      {info && <PathEntryInfo info={info} />}
    </VStack>
  );
}
