import React from 'react';
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import NextLink from 'next/link';
import { useLocation } from '@hooks';

type TitleWithOptionsProps = {
  title: string;
};

export function TitleWithOptions({
  title,
}: TitleWithOptionsProps): JSX.Element {
  const {
    applicationId,
    instanceId,
    query: { samlConnectionId },
  } = useLocation();

  return (
    <HStack justify='space-between'>
      <Text>{title}</Text>
      <Menu placement='bottom-end'>
        <MenuButton as={Button} data-testid='menu' variant='link'>
          <Icon boxSize='5' as={DotsVerticalIcon} />
        </MenuButton>
        <MenuList>
          <NextLink
            href={{
              pathname: `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/${samlConnectionId}/edit`,
            }}
          >
            <MenuItem
              _hover={{
                color: 'currentColor',
                bg: 'gray.50',
              }}
            >
              Edit connection
            </MenuItem>
          </NextLink>
        </MenuList>
      </Menu>
    </HStack>
  );
}
