import React from 'react';
import { Badge, Button } from '@chakra-ui/react';
import { Instance } from '@types';
import { devInstancesFirst, EnvironmentType, stripProtocol } from '@utils';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioButtonCard, RadioGroup, Modal } from '@components/common';
import { HomeUrlForm } from './HomeUrlForm';
import {
  useAnalytics,
  useDashboardCRUD,
  useInstance,
  useLocation,
} from '@hooks';

enum CreateProdInstanceSteps {
  SelectSetupMethod = 'select-setup-method',
  ConfigProdInstance = 'config-prod-instance',
}

const TEXTS = Object.freeze({
  [CreateProdInstanceSteps.SelectSetupMethod]: {
    subtitle: 'Select setup method',
    ctaSecondary: 'Cancel',
    trackEvent:
      'Dashboard_Create Production Instance Modal_Cancel Button Clicked',
  },
  [CreateProdInstanceSteps.ConfigProdInstance]: {
    subtitle: 'Set the home URL of your application',
    ctaSecondary: 'Back',
    trackEvent:
      'Dashboard_Create Production Instance Modal_Back Button Clicked',
  },
});

const FORM_ID = 'create-prod-instance';

export type ProdInstanceForm = {
  clone_instance_id: string;
  home_url: string;
};

interface CreateProdInstanceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ProdInstanceForm) => Promise<string | false>;
  instances: Instance[];
}

export function CreateProdInstanceModal({
  isOpen,
  onClose,
  onSubmit,
  instances,
}: CreateProdInstanceModalProps): JSX.Element {
  const [step, setStep] = React.useState<CreateProdInstanceSteps>(
    CreateProdInstanceSteps.SelectSetupMethod,
  );
  const { applicationId } = useLocation();
  const [isUpdatingInstance, setIsUpdatingInstance] = React.useState(false);
  const { create } = useDashboardCRUD();
  const { track } = useAnalytics();
  const { instance } = useInstance();

  React.useEffect(() => {
    void track(
      'Dashboard_Instance Overview_Create Production Instance Modal Viewed',
      {
        location: 'Instance Modal',
        surface: 'Dashboard',
      },
    );
  }, [track]);

  const nonProductionInstances = React.useMemo(
    () =>
      instances
        ? instances
            .filter(i => i.environment_type !== EnvironmentType.Production)
            .sort(devInstancesFirst)
        : [],
    [instances],
  );

  const cloneInstanceIdDefaultValue = nonProductionInstances[0]?.id;

  const formMethods = useForm<ProdInstanceForm>({
    defaultValues: {
      clone_instance_id: cloneInstanceIdDefaultValue,
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    control,
    setError,
    getValues,
  } = formMethods;

  const handleClose = () => {
    setStep(CreateProdInstanceSteps.SelectSetupMethod);
    onClose();
    reset();
  };

  const handleSecondaryClick = () => {
    track(TEXTS[step].trackEvent, {
      location: 'Instance Modal',
      surface: 'Dashboard',
    });

    if (step === CreateProdInstanceSteps.SelectSetupMethod) {
      handleClose();
    }
    setStep(CreateProdInstanceSteps.SelectSetupMethod);
    reset();
  };

  const nextStep = async e => {
    e.preventDefault();

    track(
      'Dashboard_Create Production Instance Modal_Continue Button Clicked',
      {
        location: 'Instance Modal',
        surface: 'Dashboard',
        setupMethod:
          instance?.environment_type !== EnvironmentType.Production
            ? `Clone ${instance?.environment_type} instance`
            : 'Create default instance',
      },
    );

    if (getValues('clone_instance_id')) {
      setIsUpdatingInstance(true);

      try {
        const res = (await create(
          `/v1/applications/${applicationId}/validate_cloning`,
          {
            clone_instance_id: cloneInstanceIdDefaultValue,
          },
        )) as any;

        if (res.status === 402) {
          return handleClose();
        }

        setStep(CreateProdInstanceSteps.ConfigProdInstance);
      } catch (err) {
        return;
      } finally {
        setIsUpdatingInstance(false);
      }
    } else {
      setStep(CreateProdInstanceSteps.ConfigProdInstance);
    }
  };

  const onCreateInstance = async ({
    home_url,
    clone_instance_id,
  }: ProdInstanceForm) => {
    const errorMessage = await onSubmit({
      home_url: stripProtocol(home_url),
      clone_instance_id,
    });

    if (errorMessage) {
      setError('home_url', {
        type: 'manual',
        message: errorMessage,
      });
      return;
    }
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title='Create production instance'
      subtitle={TEXTS[step].subtitle}
    >
      <Modal.Body>
        <form id={FORM_ID} onSubmit={handleSubmit(onCreateInstance)}>
          {step === CreateProdInstanceSteps.SelectSetupMethod ? (
            <RadioGroup name='clone_instance_id' control={control}>
              {nonProductionInstances.map(i => {
                return (
                  <RadioButtonCard
                    key={i.id}
                    value={i.id}
                    label={
                      <>
                        Clone {i.environment_type} instance{' '}
                        <Badge size='sm'>Easiest</Badge>
                      </>
                    }
                    hint={`Authentication and theme settings will be copied from the ${i.environment_type} instance. Usage of premium features will require a plan upgrade.`}
                  />
                );
              })}
              <RadioButtonCard
                value=''
                label={'Create default instance'}
                hint={'Use the default authentication and theme settings'}
              />
            </RadioGroup>
          ) : (
            <FormProvider {...formMethods}>
              <HomeUrlForm />
            </FormProvider>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer flexDir='row-reverse'>
        {step === CreateProdInstanceSteps.SelectSetupMethod ? (
          <Button onClick={nextStep} isLoading={isUpdatingInstance}>
            Continue
          </Button>
        ) : (
          <Button
            type='submit'
            form={FORM_ID}
            isLoading={isSubmitting}
            onClick={() => {
              track(
                'Dashboard_Create Production Instance Modal_Create Instance Button Clicked',
                {
                  location: 'Instance Modal',
                  surface: 'Dashboard',
                },
              );
            }}
          >
            Create Instance
          </Button>
        )}
        <Button variant='ghost' onClick={handleSecondaryClick} px='6'>
          {TEXTS[step].ctaSecondary}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
