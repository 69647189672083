import React from 'react';

import { Input } from '@chakra-ui/react';

import {
  CardDualPanel,
  ReadonlyInputBox,
  InputBox,
  CopyTextInput,
} from '@components/common';

import { useFormContext } from 'react-hook-form';
import { ProfileForm } from './types';
import { isSlug } from '@utils';

type ProfileProps = {
  createdAt: string;
  id: string;
  logoUploader: React.ReactNode;
  slug: string;
  hasError: boolean;
  mutate: () => void;
};

type FormContext = Pick<
  ProfileForm,
  'name' | 'max_allowed_memberships' | 'slug'
>;

const validateSlug = (value: string) => {
  if (!value) {
    return true;
  }

  if (!isSlug(value)) {
    return 'A slug must contain only lowercase alphanumeric characters and the dash "-" symbol';
  }
};

const Profile = ({
  createdAt,
  id,
  slug,
  logoUploader,
  hasError,
  mutate,
}: ProfileProps): JSX.Element => {
  const { register, formState } = useFormContext<FormContext>();
  const { errors } = formState;

  return (
    <CardDualPanel
      title='Profile'
      subtitle='Manage orgranization profile'
      hasError={hasError}
      mutate={mutate}
    >
      <CopyTextInput label='Organization ID' valueToCopy={id} />

      {slug && (
        <InputBox error={errors['slug']} label='Slug'>
          <Input
            {...register('slug', {
              required: 'A slug for this organization is required',
              validate: validateSlug,
            })}
          />
        </InputBox>
      )}

      <ReadonlyInputBox description={`${createdAt}`} label='Created' />

      <InputBox error={errors['name']} label='Name'>
        <Input
          {...register('name', {
            required: 'Name is required',
          })}
        />
      </InputBox>

      {logoUploader}
    </CardDualPanel>
  );
};

export default React.memo(Profile);
