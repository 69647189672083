import React from 'react';
import { HelperLink, InformationBox, VariableWidget } from '@components/common';
import { useFeatureFlags, useInstance } from '@hooks';
import { useRouter } from 'next/router';
import { FeatureFlags, Instance, makeJwtTemplateStarterList } from '@types';
import { Box, Text } from '@chakra-ui/react';
import { SUPPORTED_FEATURES } from '@constants';
import {
  BookOpenIcon,
  CodeIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

const deprecatedWarning = (oldKey: string, newKey: string) =>
  `${oldKey} is deprecated. Please use ${newKey} instead.`;

type TagType = {
  name: string;
  isEnabled: boolean;
  warning?: string;
};

const createTags: (
  instance: Instance,
  opts: { featureFlags: FeatureFlags },
) => TagType[] = instance => {
  const orgsEnabled = instance?.organization_settings.enabled;
  const impersonationEnabled = instance?.supported_features.includes(
    SUPPORTED_FEATURES.impersonation,
  );

  return [
    { name: 'user.id', isEnabled: true },
    { name: 'user.external_id', isEnabled: true },
    { name: 'user.first_name', isEnabled: true },
    { name: 'user.last_name', isEnabled: true },
    { name: 'user.full_name', isEnabled: true },
    { name: 'user.username', isEnabled: true },
    { name: 'user.created_at', isEnabled: true },
    { name: 'user.updated_at', isEnabled: true },
    { name: 'user.primary_email_address', isEnabled: true },
    { name: 'user.primary_phone_number', isEnabled: true },
    { name: 'user.email_verified', isEnabled: true },
    { name: 'user.phone_number_verified', isEnabled: true },
    {
      name: 'user.image_url',
      isEnabled: true,
    },
    {
      name: 'user.profile_image_url',
      isEnabled: true,
      warning: deprecatedWarning('user.profile_image_url', 'user.image_url'),
    },
    { name: 'user.two_factor_enabled', isEnabled: true },
    { name: 'user.organizations', isEnabled: orgsEnabled },
    { name: 'user.public_metadata', isEnabled: true },
    { name: 'user.unsafe_metadata', isEnabled: true },
    { name: 'org.id', isEnabled: orgsEnabled },
    { name: 'org.role', isEnabled: orgsEnabled },
    { name: 'org.name', isEnabled: orgsEnabled },
    { name: 'org.slug', isEnabled: orgsEnabled },
    { name: 'org.public_metadata', isEnabled: orgsEnabled },
    {
      name: 'org_membership.public_metadata',
      isEnabled: orgsEnabled,
    },
    {
      name: 'org.image_url',
      isEnabled: orgsEnabled,
    },
    { name: 'session.actor', isEnabled: impersonationEnabled },
  ];
};

type JsonVariablesProps = {
  handleVariableClick: (text: string) => void;
};

export function JsonVariables({
  handleVariableClick,
}: JsonVariablesProps): JSX.Element {
  const { instance } = useInstance();
  const { featureFlags } = useFeatureFlags();

  const enabledTags = React.useMemo(
    () =>
      createTags(instance, { featureFlags })
        .filter(tag => tag.isEnabled)
        .map(tag => ({ label: tag.name, warning: tag.warning })),
    [instance, featureFlags],
  );

  return (
    <VariableWidget
      variableName='shortcode'
      variables={enabledTags}
      delimiters={['"{{', '}}"']}
      handleVariableClick={handleVariableClick}
      helpSection={<HelpSection />}
    />
  );
}

function HelpSection(): JSX.Element {
  const { query } = useRouter();
  const { starter } = query;

  const jwtTemplateStarter = makeJwtTemplateStarterList().find(
    ({ vendor, enabled }) => enabled && vendor === (starter as string),
  );

  return (
    <>
      <Text textStyle='sm-medium' color='gray.500'>
        Need help?
      </Text>

      {starter != 'blank' && jwtTemplateStarter && (
        <InformationBox icon={CodeIcon} textStyle='sm-normal' color='gray.500'>
          <Box display='inline-block'>
            Learn more about JWT claims for{' '}
            <HelperLink
              href={jwtTemplateStarter.docs_url}
              display='inline-block'
            >
              {jwtTemplateStarter.label}
            </HelperLink>
            .
          </Box>
        </InformationBox>
      )}

      <InformationBox
        icon={BookOpenIcon}
        textStyle='sm-normal'
        color='gray.500'
      >
        <Box display='inline-block'>
          Learn how to use the{' '}
          <HelperLink
            href='https://clerk.com/docs/request-authentication/jwt-templates#template-basics'
            display='inline-block'
          >
            template syntax
          </HelperLink>
          .
        </Box>
      </InformationBox>

      <InformationBox
        icon={InformationCircleIcon}
        textStyle='sm-normal'
        color='gray.500'
      >
        <Box>
          Some additional claims will be automatically included in your tokens.
          Learn more about{' '}
          <HelperLink
            display='span'
            href='https://clerk.com/docs/request-authentication/jwt-templates#default-claims'
          >
            default claims
          </HelperLink>
          .
        </Box>
      </InformationBox>
    </>
  );
}
