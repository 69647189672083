import React from 'react';

import { CardDualPanel } from '@components/common';

import { Text } from '@chakra-ui/react';

import ClearSubdomain from './ClearSubdomain';
import ChangeSubdomain from './ChangeSubdomain';

const ManageSubdomain = ({
  previousSubdomain,
  domain,
  onSubdomainChange,
}: {
  previousSubdomain: string;
  domain: string;
  onSubdomainChange: () => void;
}): JSX.Element => {
  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title={previousSubdomain ? 'Change subdomain' : 'Set subdomain'}
      subtitle={
        <Text as='span' textStyle='sm-normal' color='gray.500'>
          Currently deployed on https://
          <Text as='span' textStyle='sm-medium' color='gray.500'>
            {previousSubdomain ? `${previousSubdomain}.` : ''}
          </Text>
          <Text as='span'>{domain}</Text>
        </Text>
      }
    >
      <ChangeSubdomain
        domain={domain}
        previousSubdomain={previousSubdomain}
        onSubdomainChange={onSubdomainChange}
      />

      <ClearSubdomain
        domain={domain}
        previousSubdomain={previousSubdomain}
        onSubdomainChange={onSubdomainChange}
      />
    </CardDualPanel>
  );
};

export default React.memo(ManageSubdomain);
