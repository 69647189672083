import React from 'react';
import { Text } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { RegisteredSwitchInfoField as SwitchInfoField } from '@components/common/Switch';
import { useFeatureFlags } from '@hooks';

export const OrganizationPermissions = (): JSX.Element => {
  const {
    featureFlags: { allow_org_admin_delete },
    isLoading: isFeatureFlagsLoading,
  } = useFeatureFlags();

  if (isFeatureFlagsLoading || !allow_org_admin_delete) {
    return <></>;
  }

  return (
    <CardDualPanel
      title='Organization permissions'
      subtitle={
        <>
          <Text>
            Define the permissions for this organization. This overrides the
            default permissions as defined in Organization Settings.
          </Text>
        </>
      }
    >
      <SwitchInfoField
        description=''
        title='Allow organization admins to delete this organization'
        name='admin_delete_enabled'
        aria-label='Allow organization admins to delete this organization'
      />
    </CardDualPanel>
  );
};
