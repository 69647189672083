import React, { useState } from 'react';

import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';

import { PencilAltIcon } from '@heroicons/react/solid';

import { VerticalInputBox } from '../common';

import { useFormContext, useWatch } from 'react-hook-form';

interface JwtSigningKeyInputProps {
  hasStoredKey: boolean;
}

export function JwtSigningKeyInput({
  hasStoredKey,
  ...rest
}: JwtSigningKeyInputProps): JSX.Element {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
    resetField,
  } = useFormContext();

  const [editMode, setEditMode] = useState(!hasStoredKey);

  const customSigningKey = useWatch({ control, name: 'custom_signing_key' });

  const placeholder = hasStoredKey
    ? 'Enter a new key or leave empty to use stored key'
    : 'Enter a valid key';

  const validateSigningKey = (value: string): boolean | string => {
    if (!hasStoredKey && customSigningKey && !value) {
      return 'Signing key is required';
    }
    return true;
  };

  const onCancelEdit = () => {
    setEditMode(false);
    resetField('signing_key');
  };

  return (
    <VerticalInputBox label='Signing key' htmlFor='signing_key' {...rest}>
      <FormControl isInvalid={!!errors.signing_key}>
        {!editMode && (
          <>
            <HStack>
              <Text>••••••••••••••••••••••••••••••••••••••••</Text>

              <Spacer />

              <IconButton
                aria-label='Edit'
                variant='ghost'
                icon={<Icon as={PencilAltIcon} boxSize='5' />}
                onClick={() => setEditMode(true)}
              />
            </HStack>

            <FormErrorMessage>{errors.signing_key?.message}</FormErrorMessage>
          </>
        )}

        {editMode && (
          <>
            <Textarea
              rows={3}
              height='auto'
              id='signing_key'
              placeholder={placeholder}
              size='sm'
              borderRadius='md'
              {...register('signing_key', {
                validate: validateSigningKey,
              })}
              disabled={isSubmitting}
            />

            <FormErrorMessage>{errors.signing_key?.message}</FormErrorMessage>

            {hasStoredKey && (
              <Button variant='link' onClick={onCancelEdit} size='xs'>
                Cancel
              </Button>
            )}
          </>
        )}
      </FormControl>
    </VerticalInputBox>
  );
}
