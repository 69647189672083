import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { Badge, Code, Button, HStack } from '@chakra-ui/react';
import { CopyTextButton } from '..';
import { DNSProxyWarning } from './DNSProxyWarning';
import { DNSRecord, Statuses } from '@types';
import { StatusBox } from '../common';
import { useAnalytics } from '@hooks';

const MAIL_ENTRY_REGEX = /dkim\d{0,1}\.|mail\./;

type DNSEntryProps = {
  record: DNSRecord;
  handleRetry: () => void;
};

export function DNSEntry({
  record: { from, to, verified, clerk_subdomain },
  handleRetry,
}: DNSEntryProps): JSX.Element {
  const isMailEntry = to.match(MAIL_ENTRY_REGEX);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const { track } = useAnalytics();

  const onRetry = () => {
    track('Dashboard_DNS Settings Screen_Retry Clicked', {
      location: 'DNS Settings Screen',
      surface: 'Dashboard',
      clickedOn: `cname_host_${clerk_subdomain}`,
    });

    setIsRefreshing(true);
    handleRetry();
    window.setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  return (
    <HStack spacing='2' align='start'>
      <StatusBox
        status={verified ? Statuses.Complete : Statuses.Failed}
        mt='7px'
      />
      <Flex alignItems='flex-start' direction='column'>
        <Text textStyle='md-medium'>
          {from} {isMailEntry && <DNSProxyWarning />}
        </Text>
        <Text>
          <Text textStyle='sm-normal' color='gray.500' as='span'>
            Add a CNAME record for{' '}
          </Text>
          <Code colorScheme='gray'>{clerk_subdomain}</Code>
          <CopyTextButton
            ml={2}
            valueToCopy={clerk_subdomain}
            onTrack={() => {
              track('Dashboard_DNS Settings Screen_Copy Clicked', {
                surface: 'Dashboard',
                location: 'DNS Settings Screen',
                clickedOn: `cname_host_${clerk_subdomain}`,
              });
            }}
          />
        </Text>
        <Text mt='0.25' textStyle='sm-normal' color='gray.500'>
          pointing to
        </Text>
        <Text mt='0.25'>
          <Code colorScheme='gray'>{to}</Code>
          <CopyTextButton
            ml={2}
            valueToCopy={to}
            onTrack={() => {
              track('Dashboard_DNS Settings Screen_Copy Clicked', {
                surface: 'Dashboard',
                location: 'DNS Settings Screen',
                clickedOn: `cname_value_${clerk_subdomain}`,
              });
            }}
          />
        </Text>
        <Flex alignItems='center' mt='0.5rem'>
          <DNSVerificationBadge verified={verified} />
          {!verified && (
            <Button
              variant='link'
              size='sm'
              ml='0.5rem'
              _focus={{ bg: 'none' }}
              onClick={onRetry}
              isDisabled={isRefreshing}
            >
              Retry
            </Button>
          )}
        </Flex>
      </Flex>
    </HStack>
  );
}

function DNSVerificationBadge({ verified }: { verified: boolean }) {
  return (
    <Badge size='md' colorScheme={verified ? 'success' : 'danger'}>
      {verified ? 'Verified' : 'DNS update needed'}
    </Badge>
  );
}
