import { Instance } from '@types';
import React from 'react';
export const URL_OPTIONS = Object.freeze([
  {
    title: 'Development origin',
    subtitle:
      'Define a development origin that will be used if one isn’t automatically.',
    hint: 'Clerk automatically detects the development origin in your local environment (e.g. http://localhost:3000). However, some features - like User Impersonation, OAuth2 SSO, or SAML - are initiated externally from your application and require redirection to pre-specified development origin.',
    fields: [
      {
        title: 'Development origin URL',
        subtitle: 'Specify the URL to use as a fallback.',
        inputPlaceholder: 'http://localhost:3000',
        customPathKey: 'development_origin',
        urlKey: 'default_development_origin',
        applyDefaultValue: true,

        transform: {
          input: (value: string) => {
            if (!value) {
              return '';
            }
            return value;
          },
          output: (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            if (!value) {
              return '';
            }
            return value;
          },
        },
        showPathPrefix: false,
      },
    ],
    isVisible: ({
      environment_type,
    }: {
      environment_type: Instance['environment_type'];
      allow_development_origin: boolean;
    }) => environment_type === 'development',
  },
]);
export const PATH_OPTIONS = Object.freeze([
  {
    title: 'Home',
    subtitle: 'Configure where your application’s home page lives.',
    fields: [
      {
        title: 'Home URL',
        subtitle: 'Specify the URL where your application’s home page lives.',
        inputPlaceholder: '(e.g. /dashboard)',
        /* Key corresponding to the instance display_config */
        customPathKey: 'home_path',
        urlKey: 'default_home_url',
        showPathPrefix: true,
        isRedirectPath: true,
      },
    ],
  },
  {
    title: 'Sign-up',
    subtitle:
      'Configure where your sign-up page lives, and how relevant redirects behave.',
    fields: [
      {
        title: 'Sign-up URL',
        subtitle: 'Specify the URL where your sign-up page lives.',
        inputPlaceholder: '(e.g. /sign-up)',
        customPathKey: 'sign_up_path',
        urlKey: 'default_sign_up_url',
        showPathPrefix: true,
      },
      {
        title: 'After sign-up redirect',
        subtitle: 'Specify where to redirect after a user signs up.',
        inputPlaceholder: '(e.g. /dashboard)',
        customPathKey: 'after_sign_up_path',
        urlKey: 'default_after_sign_up_url',
        showPathPrefix: true,
        isRedirectPath: true,
      },
    ],
  },
  {
    title: 'Sign-in',
    subtitle:
      'Configure where your sign-in page lives, and how relevant redirects behave.',
    fields: [
      {
        title: 'Sign-in URL',
        subtitle: 'Specify the URL where your sign-in page lives.',
        inputPlaceholder: '/sign-in',
        customPathKey: 'sign_in_path',
        urlKey: 'default_sign_in_url',
        showPathPrefix: true,
      },
      {
        title: 'After sign-in redirect',
        subtitle: 'Specify where to redirect after a user signs in.',
        inputPlaceholder: '(e.g. /dashboard)',
        customPathKey: 'after_sign_in_path',
        urlKey: 'default_after_sign_in_url',
        showPathPrefix: true,
        isRedirectPath: true,
      },
      {
        title: 'Session-switch redirect',
        subtitle: 'Set where to send the user after switching accounts',
        inputPlaceholder: '(e.g. /dashboard)',
        customPathKey: 'after_switch_session_path',
        urlKey: 'default_after_switch_session_url',
        availablelInMultiSession: true,
        showPathPrefix: true,
        isRedirectPath: true,
      },
    ],
  },
  {
    title: 'User profile',
    subtitle: 'Configure where your user profile page lives.',
    fields: [
      {
        title: 'User profile URL',
        subtitle: 'Specify the URL where your user profile page lives.',
        inputPlaceholder: '(e.g. /user)',
        customPathKey: 'user_profile_path',
        urlKey: 'default_user_profile_url',
        showPathPrefix: true,
      },
    ],
  },
  {
    title: 'Organization profile',
    subtitle:
      'Configure where your organization profile page lives, and how relevant redirects behave.',
    requiresOrganizations: true,
    fields: [
      {
        title: 'Organization profile URL',
        subtitle: 'Specify the URL where your organization profile lives.',
        inputPlaceholder: '(e.g. /organization)',
        customPathKey: 'organization_profile_path',
        urlKey: 'default_organization_profile_url',
        showPathPrefix: true,
      },
      {
        title: 'After leave organization redirect',
        subtitle:
          'Specify where to redirect after a user leaves an organization.',
        inputPlaceholder: '(e.g. /dashboard)',
        customPathKey: 'after_leave_organization_path',
        urlKey: 'default_after_leave_organization_url',
        showPathPrefix: true,
        isRedirectPath: true,
      },
    ],
  },
  {
    title: 'Create organization',
    subtitle:
      'Configure where your create organization page lives, and how relevant redirects behave.',
    requiresOrganizations: true,
    fields: [
      {
        title: 'Create organization URL',
        subtitle: 'Specify the URL where your create organization page lives.',
        inputPlaceholder: '(e.g. /create-organization)',
        customPathKey: 'create_organization_path',
        urlKey: 'default_create_organization_url',
        showPathPrefix: true,
      },
      {
        title: 'After create organization redirect',
        subtitle:
          'Specify where to redirect after a user creates an organization.',
        inputPlaceholder: '(e.g. /dashboard)',
        customPathKey: 'after_create_organization_path',
        urlKey: 'default_after_create_organization_url',
        showPathPrefix: true,
        isRedirectPath: true,
      },
    ],
  },
  {
    title: 'Sign-out',
    subtitle: 'Configure how your sign-out redirect behaves.',
    fields: [
      {
        title: 'After sign-out redirect',
        subtitle: 'Specify where to redirect after a user signs out.',
        inputPlaceholder: '(e.g. /sign-in/choose)',
        customPathKey: 'after_sign_out_one_path',
        urlKey: 'default_after_sign_out_one_url',
        availablelInMultiSession: true,
        showPathPrefix: true,
        isRedirectPath: true,
      },
      {
        title: 'Sign-out redirect, all accounts',
        subtitle:
          'Set where to send the user after signing out of all accounts',
        inputPlaceholder: '(e.g. /sign-in)',
        customPathKey: 'after_sign_out_all_path',
        urlKey: 'default_after_sign_out_all_url',
        showPathPrefix: true,
        isRedirectPath: true,
      },
    ],
  },
]);
