import React from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
  Box,
  Text,
} from '@chakra-ui/react';
import {
  BookOpenIcon,
  MailIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid';
import { DiscordIcon } from '..';
import { SupportButtonListItem } from './SupportButtonListItem';

import { MemoizedFeedbackWithIntercom as FeedBackListItem } from './Feedback';

import { useAuth } from '@clerk/nextjs';
import { useAnalytics } from '@hooks/useAnalytics';

export function Help(): JSX.Element {
  const { track } = useAnalytics();
  const { actor } = useAuth();

  const trackHelpMenuItem = (clickedOn: string) => {
    track('Dashboard_Top Nav_Get Help Menu Item Clicked', {
      surface: 'Dashboard',
      location: 'Top Nav',
      clickedOn,
    });
  };

  return (
    <Popover placement='bottom-end'>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              leftIcon={<Icon as={QuestionMarkCircleIcon} boxSize={5} />}
              variant='gray'
              textTransform='none'
              color='black'
              bg={isOpen && 'gray.50'}
              px={3}
              py={0}
              height='32px'
              onClick={() => {
                track('Dashboard_Top Nav_Menu Item Clicked', {
                  surface: 'Dashboard',
                  location: 'Top Nav',
                  clickedOn: 'Help',
                });
              }}
            >
              Get help
            </Button>
          </PopoverTrigger>

          <PopoverContent rounded='2xl'>
            <Box p={6}>
              <Text textStyle='lg-medium' mb={1}>
                Ask Clerk
              </Text>

              <Text textStyle='sm-normal' color='gray.500' mb={4}>
                Get help with setting up and using Clerk for your application
              </Text>

              <Box>
                <SupportButtonListItem
                  title='Join Discord'
                  href='https://discord.com/invite/b5rXHjAg7A'
                  icon={
                    <Icon
                      as={DiscordIcon}
                      color='gray.300'
                      boxSize={4}
                      mr='0.5rem'
                    />
                  }
                  onClick={() => trackHelpMenuItem('Join Discord')}
                />
                <SupportButtonListItem
                  title='Read documentation'
                  href='https://clerk.com/docs'
                  icon={
                    <Icon
                      as={BookOpenIcon}
                      color='gray.300'
                      boxSize={4}
                      mr='0.5rem'
                    />
                  }
                  onClick={() => trackHelpMenuItem('Read documentation')}
                />
                <SupportButtonListItem
                  title='Email support'
                  href='mailto:support@clerk.com?subject=Support%20request%20for%20Clerk'
                  icon={
                    <Icon
                      as={MailIcon}
                      color='gray.300'
                      boxSize={4}
                      mr='0.5rem'
                    />
                  }
                  onClick={() => trackHelpMenuItem('Email support')}
                />
                {!actor && (
                  <Box onClick={onClose}>
                    <FeedBackListItem />
                  </Box>
                )}
              </Box>
            </Box>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}
