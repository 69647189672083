import {
  Box,
  Button,
  Center,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation, useToast } from '@hooks';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import type { InstanceKey } from '@types';
import { InstanceKeyObject } from '@types';
import { DeleteSecretKeyModal } from './DeleteSecretKeyModal';
import { CreateSecretKeyModal } from './CreateSecretKeyModal';
import { HelperLink, CardDualPanel } from '@components/common';
import { HTTPError } from '@utils';
import { SecretKeyList } from '@components/apiKeys/SecretKeyList';
import { useAnalytics } from '@hooks/useAnalytics';

enum ModalType {
  DeleteSecretKey = 'deleteSecretKey',
  CreateInstanceKey = 'createInstanceKey',
}

export function SecretKeys(): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();
  const { showErrorToast } = useToast();
  const {
    data: instanceKeys,
    isValidating,
    mutate,
  } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );
  const [activeModal, setActiveModal] = React.useState<ModalType>();
  const [activeInstanceKeyId, setActiveInstanceKeyId] =
    React.useState<InstanceKey>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { del } = useDashboardCRUD<InstanceKey>();

  const handleCreateKey = () => {
    setActiveModal(ModalType.CreateInstanceKey);
    onOpen();

    track(
      'Dashboard_API Keys Screen_Secret Keys Add Another Key Button Clicked',
      {
        surface: 'Dashboard',
        location: 'API Keys Screen',
      },
    );
  };

  const handleDeleteSecretKey = React.useCallback(async () => {
    try {
      await del(
        `/v1/instances/${instanceId}/instance_keys/${activeInstanceKeyId}`,
      );
      void mutate();
      onClose();
    } catch (err) {
      const errorMessage = (err as HTTPError)?.globalErrors[0];
      showErrorToast(
        errorMessage.message || 'Something went wrong, please try again later.',
      );
      onClose();
    }
  }, [activeInstanceKeyId]);

  const handleConfirmDelete = React.useCallback(async instanceKeyId => {
    setActiveModal(ModalType.DeleteSecretKey);
    setActiveInstanceKeyId(instanceKeyId);
    onOpen();
  }, []);

  const handleCreateSecretKeyClose = React.useCallback(
    ({ revalidateKeys }) => {
      if (revalidateKeys) {
        void mutate();
      }
      onClose();
    },
    [mutate],
  );

  const secretKeys =
    instanceKeys
      ?.filter(key => key.object === InstanceKeyObject.SecretKey)
      ?.sort((i1, i2) => i2.created_at - i1.created_at) || [];

  const isDeleteModalOpen = isOpen && activeModal === ModalType.DeleteSecretKey;

  const isCreateModalOpen =
    isOpen && activeModal === ModalType.CreateInstanceKey;

  return (
    <CardDualPanel
      spacing={1}
      rightPanelStyles={{ overflow: 'hidden', flex: 1 }}
      title='Secret keys'
      subtitle={
        <>
          <Text as='span' textStyle='sm-normal' mt='2'>
            These are the secret keys to be used from your backend code. They
            are sensitive and should be deleted if leaked.
          </Text>

          <HelperLink
            display='block'
            mt='2'
            href='https://clerk.com/docs/reference/backend-api'
            onClick={() => {
              track(
                'Dashboard_API Keys Screen_Secret Keys Lean More Link Clicked',
                {
                  surface: 'Dashboard',
                  location: 'API Keys Screen',
                },
              );
            }}
          >
            Learn more
          </HelperLink>
        </>
      }
    >
      <Box overflow='hidden'>
        {isValidating ? (
          <Center height='200px'>
            <Spinner />
          </Center>
        ) : (
          <SecretKeyList
            secretKeys={secretKeys}
            onDelete={handleConfirmDelete}
          />
        )}
        <Button
          ml={2}
          mt={4}
          onClick={handleCreateKey}
          disabled={isValidating}
          variant='link'
          textTransform='none'
        >
          + Add another key
        </Button>
        <CreateSecretKeyModal
          isOpen={isCreateModalOpen}
          onClose={handleCreateSecretKeyClose}
        />
        <DeleteSecretKeyModal
          isOpen={isDeleteModalOpen}
          onDelete={handleDeleteSecretKey}
          onClose={onClose}
          isDeleting={isValidating}
        />
      </Box>
    </CardDualPanel>
  );
}
