import { CardDualPanel, CopyTextInput } from '@components/common';
import React from 'react';
import { useGetSamlConnection } from '@components/samlConnections/api';
import { useRouter } from 'next/router';

export function SamlConnectionSPDetails(): JSX.Element {
  const { query } = useRouter();
  const { samlConnectionId } = query as Record<string, string | undefined>;
  const { data: samlConnection, isLoading } =
    useGetSamlConnection(samlConnectionId);

  if (isLoading || !samlConnection) {
    return null;
  }

  return (
    <CardDualPanel
      title='Service provider details'
      subtitle='These are the values to add to your identity provider.'
    >
      <CopyTextInput
        label='Assertion consumer service URL'
        valueToCopy={samlConnection.acs_url}
      />

      <CopyTextInput
        label='Service provider entity ID'
        valueToCopy={samlConnection.sp_entity_id}
      />
    </CardDualPanel>
  );
}
