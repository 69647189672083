import { RecentActivity } from '@components/home';
import { TodosProvider } from '@components/instanceHome/gettingStarted/contexts';

import React, { useEffect, useState } from 'react';
import { HStack, Spinner, Center, Text, Tooltip, Icon } from '@chakra-ui/react';

import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { useLocation } from '@hooks';

import { CardSinglePanel } from '@components/common';
import { enUS } from 'date-fns/locale';
import { InformationCircleIcon } from '@heroicons/react/solid';

import { format, fromUnixTime, isToday } from 'date-fns';

const UsersAvailableStateDisplay = () => {
  const { instanceId } = useLocation();
  const [mauLastUpdated, setMauLastUpdated] = useState(null);

  const {
    data: monthlyMetrics = {},
    error: monthlyMetricsError,
    mutate: mutateMonthlyMetrics,
    isValidating,
  } = useDashboardSWR(`/v1/instances/${instanceId}/analytics/monthly_metrics`);

  const {
    total_users = 0,
    month = new Date().getMonth(),
    year = new Date().getFullYear(),
    active_users_previous_update_date = null,
    active_users = 0,
    signups = 0,
    signins = 0,
  } = monthlyMetrics;

  useEffect(() => {
    if (active_users_previous_update_date) {
      // Convert Unix timestamp to date instance
      const dateFromTimestamp = fromUnixTime(
        active_users_previous_update_date / 1000,
      );

      // Check if the date is today
      if (isToday(dateFromTimestamp)) {
        const time = format(dateFromTimestamp, 'HH:mm');
        setMauLastUpdated(`today ${time}`);
      } else {
        setMauLastUpdated(format(dateFromTimestamp, 'MMMM d, HH:mm'));
      }
    }
  }, [active_users_previous_update_date]);

  const date = `${enUS.localize.month(month - 1)} ${year}`;

  return (
    <TodosProvider>
      <HStack spacing={3} align='stretch'>
        <CardSinglePanel
          title={
            <span>
              Total users
              <Tooltip
                hasArrow
                placement='top'
                label='Updated just now'
                bg='gray.900'
              >
                <Icon
                  as={InformationCircleIcon}
                  color='gray.300'
                  boxSize={4}
                  ml={2}
                />
              </Tooltip>
            </span>
          }
          subtitle='All time'
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{total_users}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel
          title={
            <span>
              Active users
              {mauLastUpdated && (
                <Tooltip
                  hasArrow
                  placement='top'
                  label={`Last update ${mauLastUpdated}`}
                  bg='gray.900'
                >
                  <Icon
                    as={InformationCircleIcon}
                    color='gray.300'
                    boxSize={4}
                    ml={2}
                  />
                </Tooltip>
              )}
            </span>
          }
          subtitle={date}
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{active_users}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel
          title='Sign-ups'
          subtitle={date}
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{signups}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel
          title='Sign-ins'
          subtitle={date}
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{signins}</Text>
          )}
        </CardSinglePanel>
      </HStack>

      <RecentActivity />
    </TodosProvider>
  );
};

export default UsersAvailableStateDisplay;
