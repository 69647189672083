import React from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';

import { OrganizationsAvatar } from '../../OrganizationAvatar';
import { Organization } from '@components/organizations/core/types';

type NameCellProps = {
  organization: Pick<Organization, 'logo_url' | 'image_url' | 'name'>;
};
const NameCell = ({ organization }: NameCellProps): JSX.Element => {
  return (
    <HStack>
      <OrganizationsAvatar organizationsLogo={organization.image_url} />;
      <Box maxW='20rem' flex='1'>
        <Text textStyle='sm-medium' noOfLines={1}>
          {organization.name}
        </Text>
      </Box>
    </HStack>
  );
};

export default NameCell;
