import React from 'react';
import { Box, HStack, Icon, IconButton, Switch } from '@chakra-ui/react';
import { AttributeInfo } from './AttributeInfo';
import { CogIcon } from '@heroicons/react/solid';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { useSupportedFeature } from '@hooks';
import {
  useFormContext,
  UseFormRegisterReturn,
  useWatch,
} from 'react-hook-form';

const ACTIVE_STYLE = { bg: 'gray.50' };
const INACTIVE_STYLE = { border: '1px solid' };
const SWITCH_WIDTH = '40px';
const SWITCH_HEIGHT = '22px';

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type SwitchInfoFieldProps = {
  name: string;
  title: string | JSX.Element;
  description: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onCogClick?: () => void;
  info?: string | JSX.Element;
  badges?: React.ReactNode[];
  onChange?: (props: any) => void;
  minBadgeHeight?: string;
  isPremiumFeature?: boolean;
  'aria-label'?: string;
} & Omit<Partial<UseFormRegisterReturn>, 'onChange'>;

export const SwitchInfoField = React.forwardRef(function SwitchInfoField(
  {
    name,
    title,
    description,
    info,
    isDisabled,
    isChecked,
    badges,
    minBadgeHeight,
    onChange,
    onCogClick,
    isPremiumFeature,
    ...rest
  }: SwitchInfoFieldProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  return (
    <HStack
      key={name}
      spacing='4'
      pos='relative'
      align='flex-start'
      p='4'
      border={'1px solid transparent'}
      borderRadius={8}
      _hover={ACTIVE_STYLE}
      borderColor='gray.50'
      {...(isChecked ? ACTIVE_STYLE : INACTIVE_STYLE)}
    >
      <Box minW={12}>
        <Switch
          aria-label={rest['aria-label']}
          id={name}
          sx={{
            '--switch-track-height': SWITCH_HEIGHT,
            '--switch-track-width': SWITCH_WIDTH,
          }}
          mt={-2}
          isDisabled={isDisabled}
          isChecked={isChecked}
          onChange={onChange}
          name={name}
          ref={ref}
        />
      </Box>
      <AttributeInfo
        title={title}
        description={description}
        info={info}
        htmlFor={name}
        badges={badges}
        minBadgeHeight={minBadgeHeight}
        isPremiumFeature={isPremiumFeature}
      />
      {isChecked && onCogClick ? (
        <IconButton
          pos='absolute'
          top='4'
          right='4'
          aria-label='Open config modal'
          icon={<Icon as={CogIcon} boxSize={5} color='gray.300' />}
          color='gray.300'
          bg='none'
          h='auto'
          minW='none'
          _active={{ bg: 'none' }}
          _focus={{ boxShadow: 'none' }}
          _hover={{
            bg: 'none',
            '& svg': {
              color: 'primary.500',
            },
          }}
          onClick={onCogClick}
          data-testid='cog-button'
        />
      ) : null}
    </HStack>
  );
});

type StatusInfo = {
  name: string;
  isDisabled?: boolean;
  isFeatureSupported?: boolean;
  handleOnChange?: () => void;
};

export function withUserFormRegister<P>(Component: React.ComponentType<P>) {
  return function WithUserFormRegister(hocProps: P & StatusInfo) {
    const {
      register,
      control,
      formState: { isSubmitting },
    } = useFormContext();
    const { name, isDisabled, handleOnChange } = hocProps;
    const isChecked: boolean = useWatch({ control, name });

    if (!name) {
      return <Component {...hocProps} />;
    }

    return (
      <Component
        {...hocProps}
        isDisabled={isDisabled || isSubmitting}
        isChecked={isChecked}
        {...register(name, {
          onChange: handleOnChange,
        })}
      />
    );
  };
}

export const RegisteredSwitchInfoField = withUserFormRegister(SwitchInfoField);

export function withPremiumFeatureGuard<P>(Component: React.ComponentType<P>) {
  return function WithBillingModal(hocProps) {
    const { resetField, getValues } = useFormContext();
    const { name, isFeatureSupported = true, featureName } = hocProps;
    const { showModal } = usePaymentRequired();
    const { hasNotSeenInfoBillingModal } = useSupportedFeature();

    const handleOnChange = () => {
      if (!isFeatureSupported) {
        resetField(name);
        return showModal({ features: [featureName] });
      }

      if (hasNotSeenInfoBillingModal(featureName) && getValues(name)) {
        showModal();
      }
    };

    return <Component {...hocProps} handleOnChange={handleOnChange} />;
  };
}

export const SwitchInfoFieldWithBillingModal = withPremiumFeatureGuard(
  RegisteredSwitchInfoField,
);
