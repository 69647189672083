import React from 'react';
import { CardDualPanel } from '@components/common';
import { Flex, Box } from '@chakra-ui/react';
import { useInstance } from '@hooks';

import { useFeatureFlags } from '@hooks';

import NextLink from 'next/link';
import { RegisteredSwitchInfoField as SwitchInfoField } from '@components/common/Switch';

const GlobalPermissions = (): JSX.Element => {
  const { instance } = useInstance();
  const isOrganizationsEnabled = instance?.organization_settings?.enabled;
  const {
    featureFlags: { allow_user_create_org, allow_user_self_delete },
    isLoading: isFeatureFlagsLoading,
  } = useFeatureFlags();

  if (
    !allow_user_create_org ||
    !allow_user_self_delete ||
    isFeatureFlagsLoading
  ) {
    return <></>;
  }

  return (
    <CardDualPanel
      title='Default user permissions'
      subtitle='Define the default settings for new users. These values can be overridden on a per-user basis in the user profile.'
      rightPanelStyles={{ flex: 1, width: 'calc(50% - 1.5rem)' }}
    >
      <Flex flexDir='column' gap={5}>
        <SwitchInfoField
          description=''
          title='Allow users to delete their accounts'
          name='actions.delete_self'
          aria-label='Allow users to delete their accounts'
        />

        <SwitchInfoField
          info={
            !isOrganizationsEnabled ? (
              <>
                This setting is currently disabled because organizations are not
                enabled for this application.
                <NextLink
                  href={`/apps/${instance?.application_id}/instances/${instance?.id}/organizations-settings`}
                >
                  <Box
                    as='span'
                    textStyle='sm-normal'
                    color='primary.500'
                    fontWeight={500}
                  >
                    Go to Organization Settings
                  </Box>
                </NextLink>{' '}
              </>
            ) : null
          }
          isDisabled={!isOrganizationsEnabled}
          description=''
          title='Allow users to create organizations'
          name='actions.create_organization'
          aria-label='Allow users to create organizations'
        />
      </Flex>
    </CardDualPanel>
  );
};

export default GlobalPermissions;
