import React from 'react';
import { Flex, Text, Box, Icon } from '@chakra-ui/react';
import NextLink from 'next/link';

import { ColorSwatchIcon, ShieldCheckIcon } from '@heroicons/react/solid';
import { useAnalytics } from '@hooks/useAnalytics';

export type LinkTile = {
  href: string;
  subtitle: string;
  title: string;
  linkTitle: string;
  id: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
};

const linkTiles: LinkTile[] = [
  {
    id: 'authentication',
    href: 'https://clerk.com/docs/request-authentication/overview',
    title: 'Request Authentication',
    subtitle: 'Securely retrieve user data from Clerk',
    linkTitle: 'Learn about authentication',
    icon: ShieldCheckIcon,
  },
  {
    id: 'appearance',
    href: 'https://clerk.com/docs/component-customization/appearance-prop',
    title: 'Customize Appearance',
    subtitle: 'Match Clerk Components to your Brand',
    linkTitle: 'Learn about Component Customization',
    icon: ColorSwatchIcon,
  },
];

const DocumentationTiles = (): JSX.Element => {
  const { track } = useAnalytics();

  return (
    <Flex gap={3}>
      {linkTiles.map(({ href, title, icon, id, linkTitle }) => (
        <Flex
          bg='#FFFFFF'
          border='1px solid rgba(0, 0, 0, 0.08)'
          borderRadius='lg'
          key={id}
          width='100%'
          boxShadow='0px 4px 4px rgba(0, 0, 0, 0.04)'
          justifyContent='center'
        >
          <Box as={NextLink} width='100%' href={href} target='_blank'>
            <Box
              width='100%'
              textStyle='sm-normal'
              color='primary.500'
              fontWeight={500}
              height={28}
              _hover={{
                bg: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 'lg',
              }}
              onClick={() => {
                track(
                  'Dashboard_Instance Overview_Start Building Card Link Clicked',
                  {
                    surface: 'Dashboard',
                    location: 'Instance Overview',
                    clickedOn: title,
                  },
                );
              }}
            >
              <Flex
                gap={2}
                height='100%'
                width='100%'
                alignItems='center'
                justifyContent='center'
                flexDirection='column'
              >
                <Icon boxSize={8} as={icon} color='gray.300' />
                <Text color='black'>{linkTitle}</Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};

export default DocumentationTiles;
