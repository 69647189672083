import React from 'react';

import { Button, Icon, Text, useDisclosure } from '@chakra-ui/react';

import { ConfirmationModal } from '@components/modal';
import { useDashboardCRUD, useLocation, useToast } from '@hooks';
import { ExclamationIcon } from '@heroicons/react/solid';

const ClearSubdomain = ({
  domain,
  previousSubdomain,
  onSubdomainChange,
}: {
  domain: string;
  previousSubdomain: string;
  onSubdomainChange: () => void;
}): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { instanceId } = useLocation();
  const { create } = useDashboardCRUD();
  const { showErrorToast, showSuccessToast } = useToast();
  const { isOpen, onClose, getButtonProps } = useDisclosure();

  async function handleClearSubdomain() {
    setIsSubmitting(true);
    const newDomain = `https://${domain}`;
    try {
      await create(`/v1/instances/${instanceId}/change_domain`, {
        home_url: newDomain,
      });
      showSuccessToast('Subdomain has been removed');
      onSubdomainChange();
      handleModalClose();
    } catch (err) {
      showErrorToast('Could not remove subdomain');
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleModalClose = () => {
    onClose();
  };

  return (
    <>
      {previousSubdomain && (
        <Button
          leftIcon={<Icon as={ExclamationIcon} />}
          fontWeight='bold'
          colorScheme='red'
          {...getButtonProps()}
          ml={2}
        >
          Clear subdomain
        </Button>
      )}
      <ConfirmationModal
        alertDialogProps={{ width: '30rem' }}
        isOpen={isOpen}
        isConfirmationLoading={isSubmitting}
        headerTitle='Clear Subdomain'
        message={
          <Text as='span' textStyle='sm-normal' color='gray.500'>
            Are you sure you want clear the subdomain for your application? This
            will change the base redirect urls for your application to{' '}
            <Text as='span' textStyle='sm-medium' color='gray.500'>
              {`https://${domain}`}
            </Text>
          </Text>
        }
        cancelationMessage='Cancel'
        confirmationMessage='Clear subdomain'
        confirmButtonIcon={ExclamationIcon}
        handleCancelation={handleModalClose}
        handleConfirmation={handleClearSubdomain}
      />
    </>
  );
};

export default ClearSubdomain;
