import React from 'react';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { Application } from '@types';
import Fuse from 'fuse.js';
import {
  Badge,
  Box,
  Center,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckIcon, SearchIcon } from '@heroicons/react/solid';
import { Selector, Applications } from '@clerk-ui/assets/icons';
import { useRouter } from 'next/router';
import { Item } from './Item';
import { CreateAppListItem } from './CreateAppListItem';
import { useAnalytics } from '@hooks/useAnalytics';
import { useLocation } from '@hooks';

const appBadgeColorScheme = {
  Free: 'gray',
  Hobby: 'yellow',
  Bussiness: 'primary',
  Enterprise: 'green',
};

const fuse = new Fuse([] as Application[], {
  keys: ['name'],
  threshold: 0.45,
});

type ApplicationSwitcherProps = {
  currentApp: Partial<Application>;
  redirectHighlighting: string | undefined;
};

export function ApplicationSwitcher({
  currentApp,
  redirectHighlighting,
}: ApplicationSwitcherProps): JSX.Element {
  const { track } = useAnalytics();
  const { onOpen, onClose, isOpen, onToggle } = useDisclosure();
  const router = useRouter();
  const [query, setQuery] = React.useState('');
  const inputRef = React.useRef();
  const { data } = useDashboardSWR<Application[]>('/v1/applications');
  const otherApps = data || [];
  const { instanceId } = useLocation();
  let activeInstance = currentApp.instances.find(({ id }) => id === instanceId);

  React.useEffect(() => {
    fuse.setCollection(otherApps);
  }, [otherApps]);

  if (!otherApps) {
    return null;
  }

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };

  const filteredApps = query
    ? fuse.search(query).map(a => a.item)
    : otherApps.sort((a, b) => b.updated_at - a.updated_at);

  const handleAppSelect = (app: Application) => {
    activeInstance = app.instances.find(({ id }) => id === instanceId);
    track('Dashboard_Top Nav_Application Menu Item Clicked', {
      surface: 'Dashboard',
      location: 'Top Nav',
      environmentType: activeInstance ? activeInstance.environment_type : '',
      clickedOn: app.name,
    });
    const { pathname, query, push } = router;
    // TODO: The backend needs to return all instance ids
    onClose();
    return push({
      pathname,
      query: {
        ...query,
        applicationId: app.id,
        instanceId: app.instances[0].id,
      },
    });
  };

  const filteredAppsListItems = filteredApps.map((a, i) => (
    <ApplicationListItem
      key={a.id}
      application={a}
      handleAppSelected={handleAppSelect}
      isSelected={query && i === 0}
      isCurrentApp={currentApp.id === a.id}
    />
  ));

  const togglePopover = () => {
    if (!otherApps.length) {
      return;
    }
    setQuery('');
    onToggle();
  };

  const selectFirstItem = (e: React.FormEvent) => {
    e.preventDefault();
    if (query && filteredApps[0]) {
      return handleAppSelect(filteredApps[0]);
    }
  };

  const AppBadge = () => {
    return (
      <Badge
        height='20px'
        ml='0.75rem'
        fontSize='0.7rem'
        colorScheme={appBadgeColorScheme[currentApp.subscription_plan_title]}
      >
        {currentApp.subscription_plan_title}
      </Badge>
    );
  };

  return (
    <Box
      overflow='hidden'
      onClick={() => {
        track('Dashboard_Top Nav_Menu Item Clicked', {
          surface: 'Dashboard',
          location: 'Top Nav',
          clickedOn: 'Application',
          environmentType: activeInstance
            ? activeInstance.environment_type
            : '',
        });
      }}
    >
      <Item
        cursor={otherApps.length ? 'pointer' : 'unset'}
        padding='0 12px'
        height='32px'
        display='flex'
        alignItems='center'
        borderRadius={5}
        bg={isOpen ? 'gray.50' : 'unset'}
        animation={redirectHighlighting}
        _hover={{ background: 'gray.50' }}
        _active={{ background: 'gray.50' }}
        outline='0'
        isActive={isOpen}
        Icon={otherApps.length ? Selector : undefined}
        onClick={togglePopover}
        justifyContent='space-between'
      >
        <Icon as={Applications} mt='0.5' />
        <Skeleton
          isLoaded={!!currentApp?.name}
          height={4}
          minW={currentApp?.name ? 'fit-content' : '5rem'}
          ml={'0 !important'}
        />
        <Text
          maxW='200px'
          textStyle='sm-medium'
          noOfLines={1}
          ml='0.75rem!important'
        >
          {currentApp?.name}
        </Text>
        <AppBadge />
      </Item>
      <Popover
        placement='bottom-start'
        initialFocusRef={inputRef}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        <PopoverTrigger>
          {/* This is used as an anchor */}
          {/* The popover will position itself relative to this div*/}
          <div />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody textStyle='md-normal'>
            <Box
              color='gray.600'
              borderBottom='1px solid'
              borderColor='gray.200'
              my={-2}
              mx={-3}
              mb={2}
            >
              <form onSubmit={selectFirstItem}>
                <InputGroup>
                  <InputLeftElement>
                    <Icon as={SearchIcon} />
                  </InputLeftElement>
                  <Input
                    ref={inputRef}
                    type='application-search'
                    placeholder='Search'
                    value={query}
                    onChange={handleQueryChange}
                    border='none'
                  />
                </InputGroup>
              </form>
            </Box>
            {filteredAppsListItems.length > 0 && (
              <List
                maxH='252px' // Show max 7 items, then scroll.
                mx={-3}
                overflow={
                  filteredAppsListItems.length > 7 ? 'scroll' : 'hidden'
                }
                textStyle='md-normal'
                css={
                  filteredAppsListItems.length <= 7
                    ? {
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }
                    : {}
                }
              >
                {filteredAppsListItems}
              </List>
            )}
            {filteredAppsListItems.length === 0 && (
              <Center m={4}>
                <Text color='gray.500'>No results</Text>
              </Center>
            )}
            <CreateAppListItem />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

function ApplicationListItem({
  application,
  handleAppSelected,
  isSelected = false,
  isCurrentApp = false,
}: {
  application: Application;
  handleAppSelected?: (app: Application) => void;
  isSelected?: boolean;
  isCurrentApp?: boolean;
}) {
  return (
    <ListItem mx={3}>
      <Link
        display='inline-flex'
        alignItems='center'
        w='full'
        py={2}
        px={2}
        rounded='base'
        bg={isSelected ? 'gray.50' : undefined}
        _hover={{
          bg: 'gray.50',
        }}
        onClick={() => handleAppSelected(application)}
      >
        <Text
          noOfLines={1}
          textStyle={isCurrentApp ? 'md-medium' : 'md-normal'}
        >
          {application.name}
        </Text>
        {isCurrentApp && (
          <Icon as={CheckIcon} color='primary.500' ml='auto' boxSize={4} />
        )}
      </Link>
    </ListItem>
  );
}
