import {
  AttributeSettings,
  OauthProviders,
  PasswordSettings,
  Actions,
} from '@types';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

export const useDependentFields = (
  formMethods: UseFormReturn<
    AttributeSettings & OauthProviders & PasswordSettings & Actions,
    object
  >,
): void => {
  const { getValues, watch, setValue } = formMethods;

  // Watch email address enabled toggle
  const isEmailAddressEnabled = watch('attributes.email_address.enabled');

  React.useEffect(() => {
    // When email address is enabled, make it verifiable on sign up via email links by default
    setValue(
      'attributes.email_address.verify_at_sign_up',
      isEmailAddressEnabled,
      {
        shouldDirty: true,
      },
    );

    if (isEmailAddressEnabled) {
      if (getValues('attributes.email_address.verifications').length === 0) {
        setValue('attributes.email_address.verifications', ['email_link'], {
          shouldDirty: true,
        });
      }
    } else {
      setValue('attributes.email_address.verifications', [], {
        shouldDirty: true,
      });
    }

    // When email address is enabled, make it an authentication first factor by default
    setValue(
      'attributes.email_address.used_for_first_factor',
      isEmailAddressEnabled,
      {
        shouldDirty: true,
      },
    );
  }, [isEmailAddressEnabled, setValue, getValues]);

  // Watch phone number enabled toggle
  const isPhoneNumberEnabled = watch('attributes.phone_number.enabled');

  React.useEffect(() => {
    // When phone number is enabled, make it verifiable on sign up via phone code by default
    setValue(
      'attributes.phone_number.verify_at_sign_up',
      isPhoneNumberEnabled,
      {
        shouldDirty: true,
      },
    );

    if (isPhoneNumberEnabled) {
      if (getValues('attributes.phone_number.verifications').length === 0) {
        setValue('attributes.phone_number.verifications', ['phone_code'], {
          shouldDirty: true,
        });
      }
    } else {
      setValue('attributes.phone_number.verifications', [], {
        shouldDirty: true,
      });
    }

    // When phone number is enabled, make it an authentication first factor by default
    setValue(
      'attributes.phone_number.used_for_first_factor',
      isPhoneNumberEnabled,
      {
        shouldDirty: true,
      },
    );
  }, [isPhoneNumberEnabled, setValue, getValues]);

  // Watch username enabled toggle
  const isUsernameEnabled = watch('attributes.username.enabled');

  React.useEffect(() => {
    // Switches on username required field when username is enabled. Optional usernames are not supported yet.
    setValue('attributes.username.required', isUsernameEnabled, {
      shouldDirty: true,
    });

    // When username is enabled, make it an authentication first factor by default
    setValue('attributes.username.used_for_first_factor', isUsernameEnabled, {
      shouldDirty: true,
    });
  }, [isUsernameEnabled, setValue]);

  // Watch first name enabled toggle
  const isFirstNameEnabled = watch('attributes.first_name.enabled');

  React.useEffect(() => {
    // Switches on/off the last name enabled field according to first name
    setValue('attributes.last_name.enabled', isFirstNameEnabled, {
      shouldDirty: true,
    });
  }, [isFirstNameEnabled, setValue]);

  // Watch first name required toggle
  const isFirstNameRequired = watch('attributes.first_name.required');

  React.useEffect(() => {
    // Switches on/off the last name required field according to first name
    setValue('attributes.last_name.required', isFirstNameRequired, {
      shouldDirty: true,
    });
  }, [isFirstNameRequired, setValue]);

  // Watch password required toggle
  const isPasswordRequired = watch('attributes.password.required');

  React.useEffect(() => {
    // Makes password enabled when it is required, password will always be enabled
    if (isPasswordRequired) {
      setValue('attributes.password.enabled', true, {
        shouldDirty: true,
      });
    }
  }, [isPasswordRequired, setValue]);

  // Watch all first factors of the page (Social Connections are excluded)
  const isEmailAddressFirstFactor = watch(
    'attributes.email_address.used_for_first_factor',
  );
  const isPhoneNumberFirstFactor = watch(
    'attributes.phone_number.used_for_first_factor',
  );
  const isUsernameFirstFactor = watch(
    'attributes.username.used_for_first_factor',
  );
  React.useEffect(() => {
    // If there are no first factor, password should be disabled
    if (
      !isEmailAddressFirstFactor &&
      !isPhoneNumberFirstFactor &&
      !isUsernameFirstFactor
    ) {
      setValue('attributes.password.required', false, {
        shouldDirty: true,
      });
    }

    // If email address is not a first factor, disable the corresponding entries in Authentication Factors section
    if (!isEmailAddressFirstFactor) {
      setValue('attributes.email_address.first_factors', [], {
        shouldDirty: true,
      });
    }

    // If phone number is not a first factor, disable the corresponding entries in Authentication Factors section
    if (!isPhoneNumberFirstFactor) {
      setValue('attributes.phone_number.first_factors', [], {
        shouldDirty: true,
      });
    }
  }, [
    isEmailAddressFirstFactor,
    isPhoneNumberFirstFactor,
    isUsernameFirstFactor,
    setValue,
  ]);
};
