import React, { useEffect } from 'react';
import { Stack } from '@chakra-ui/layout';
import { SamlConnection } from '@types';
import { useLocation, useToast } from '@hooks';
import { CardWithSwitch } from '@components/common';
import { Center, Spinner, Switch } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useGetSamlConnection,
  useUpdateSamlConnection,
} from '@components/samlConnections/api';

export function SamlConnectionAdvanced(): JSX.Element {
  const { query } = useLocation();
  const { samlConnectionId } = query as Record<string, string | undefined>;
  const { data: samlConnection, isLoading } =
    useGetSamlConnection(samlConnectionId);
  const { updateSamlConnection, mutate } = useUpdateSamlConnection();
  const { showSuccessToast, showErrorToast } = useToast();

  const formMethods = useForm<SamlConnection>({ mode: 'onChange' });
  const { register, reset } = formMethods;

  useEffect(() => {
    if (!samlConnection) {
      return;
    }

    const { sync_user_attributes } = samlConnection;
    reset({ sync_user_attributes });
  }, [samlConnection, reset]);

  const handleSyncUserAttributesChange = e => {
    return handleUpdate({ sync_user_attributes: e.target.checked });
  };

  const handleUpdate = async ({
    sync_user_attributes,
  }: {
    sync_user_attributes: boolean;
  }) => {
    try {
      await updateSamlConnection(samlConnectionId, { sync_user_attributes });
      showSuccessToast('Connections settings saved.');
      void mutate();
    } catch (_) {
      showErrorToast('Something went wrong, please try again later.');
      reset();
    }
  };

  if (isLoading && !samlConnection) {
    return (
      <Center w='full' h='full'>
        <Spinner />
      </Center>
    );
  }

  return (
    <Stack spacing='8'>
      <FormProvider {...formMethods}>
        <CardWithSwitch
          title='Sync user attributes during sign-in'
          subtitle='Each time a user signs in using this connection, check for attribute updates and update the user.'
        >
          <Switch
            {...register('sync_user_attributes')}
            onChange={handleSyncUserAttributesChange}
          />
        </CardWithSwitch>
      </FormProvider>
    </Stack>
  );
}
