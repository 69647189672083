import React from 'react';
import { useRouter } from 'next/router';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { rudderInitialize } from './rudderAnalytics';
import { useInstance } from '@hooks';

export interface AnalyticsContextType {
  page: (
    category?: string | Record<string, unknown>,
    name?: string | Record<string, unknown>,
    properties?: any,
  ) => void;
  track: (event: string, properties?: any) => void;
  identify: (userId: string, traits?: any) => void;
}
const noop = () => {
  return;
};

export const AnalyticsContext = React.createContext<AnalyticsContextType>({
  page: noop,
  track: noop,
  identify: noop,
  // TODO: Add more spec items if needed
});

export const AnalyticsProvider = (
  props: React.PropsWithChildren<Record<never, never>>,
): JSX.Element => {
  const { children } = props;
  const router = useRouter();
  const { applicationId, instanceId } = router?.query;
  const { instance } = useInstance();

  React.useEffect(() => {
    void rudderInitialize();
  }, []);

  const analytics = React.useMemo(() => {
    if (!process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY) {
      return {
        page: noop,
        track: noop,
        identify: noop,
        // TODO: Add more spec items if needed
      };
    }
    const analyticsBrowser = AnalyticsBrowser.load(
      {
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY,
        cdnURL: 'https://scdn.clerk.com',
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: 'segapi.clerk.com/v1',
            protocol: 'https',
          },
        },
      },
    );

    const page = (
      category: string | Record<string, unknown>,
      name?: string | Record<string, unknown>,
      properties?: any,
    ) => {
      window?.rudderanalytics?.page(category, name, properties);
      return analyticsBrowser.page(category, name, properties);
    };

    const defaultTrackProperties = {
      ...(applicationId ? { applicationId } : {}),
      ...(instanceId ? { instanceId } : {}),
      ...(instance?.environment_type
        ? { environmentType: instance?.environment_type }
        : {}),
    };

    const track = (event: string, properties?: any) => {
      window?.rudderanalytics?.track(event, {
        ...defaultTrackProperties,
        ...properties,
      });
      return analyticsBrowser.track(event, {
        ...defaultTrackProperties,
        ...properties,
      });
    };

    const identify = (userId: string, traits?: any) => {
      window?.rudderanalytics?.identify(userId, traits);
      return analyticsBrowser.identify(userId, traits);
    };

    return {
      page,
      track,
      identify,
    };
  }, [instance?.environment_type, applicationId, instanceId]);
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
