import React from 'react';
import NextLink from 'next/link';
import { CardContainer } from '@components/common';
import { Text, Flex, Box } from '@chakra-ui/react';
import { useLocation } from '@hooks';
import { billingPath } from '@utils';

export function SamlConnectionEnablePrompt(): JSX.Element {
  const { applicationId, instanceId } = useLocation();

  return (
    <CardContainer spacing={6} mt={4}>
      <Flex justifyContent='center'>
        <Box>
          <Text
            textStyle='sm-normal'
            display='block'
            color='gray.500'
            textAlign='center'
          >
            Enterprise Connections feature is not available in your current plan
            for production instances.
          </Text>
          <Text
            textStyle='sm-normal'
            display='block'
            color='gray.500'
            mt='2'
            textAlign='center'
          >
            <NextLink href={billingPath(applicationId, instanceId)}>
              <Box
                as='span'
                textStyle='sm-normal'
                color='primary.500'
                fontWeight={500}
              >
                Upgrade this application’s plan
              </Box>
            </NextLink>{' '}
            to enable it.
          </Text>
        </Box>
      </Flex>
    </CardContainer>
  );
}
